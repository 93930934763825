export const constants = {
    table: {
        limit: 12,
    },
    icons: 'assets/svgs/icons.svg',
    userSwitch: {
        keys: {
            localStorage: 'X-Switch-Customer',
            header: 'X-Switch-Customer',
        },
    },
    addressSwitch: {
        keys: {
            localStorage: 'X-Switch-Shipping-Address',
            header: 'X-Switch-Shipping-Address',
        },
    },
    payment: {
        methods: ['transport', 'pickup'] as const,
    },
    productCheckboxCategories: [
        {
            id: 'recently',
            value: '1000',
        },
        {
            id: 'less-frequently',
            value: '1100',
        },
    ],
    alerts: {
        skip: [],
    },
    disabledDates: [new Date('2023-12-23')],
    currency: 'EUR',
};
